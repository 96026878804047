<template>
    <div class="container">
        <div class="app__title app__title-min text-center">Шаг 1 - Краткое описание Вашего бизнеса</div>
        <div class="app__subtitle app__subtitle-min">
            Спасибо, что согласились заполнить этот чек-лист. Отвечайте честно, и сможете получить качественную диагностику маркетинга в вашей компании. Время на прохождение теста - <span>8-15 минут</span>.
        </div>
        <div v-if="error" class="alert alert-danger">{{error}}</div>
        <div class="app__form app__form-var">
            <form v-on:submit.prevent="onSubmit">
                <div class="form-group">
                    <label for="company">Наименование компании</label>
                    <input type="text" class="form-control" id="company" placeholder="Введите наименование компании" v-model="form.company"
                           required>
                </div>
                <div class="form-group">
                    <label for="site">Сайт компании</label>
                    <input type="text" class="form-control" id="site" placeholder="Введите адрес сайта вашей компании"
                           v-model="form.site"
                           required>
                </div>
                <div class="form-group">
                    <label for="product">Тип продукта</label>
                    <v-select :id="product" v-model="form.product" :options="['Услуги','Товары']" :searchable="true" :no-drop="false" :clearable="false" :placeholder="'Выберите Тип продукта'">
                        <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!form.product"
                              v-bind="attributes"
                              v-on="events"
                            />
                         </template>
                    </v-select>
                    <!--select class="form-control" id="product" v-model="form.product" required>
                        <option value="" disabled selected hidden>Выберите Тип продукта</option>
                        <option>Услуги</option>
                        <option>Товары</option>
                    </select-->
                </div>
                <div class="form-group">
                    <label for="segment">Сегмент аудитории</label>
                    <v-select :id="segment" v-model="form.segment" :options="['B2B','B2C']" :searchable="true" :no-drop="false" :clearable="false" :placeholder="'Выберите сегмент аудитории'">
                        <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!form.segment"
                              v-bind="attributes"
                              v-on="events"
                            />
                         </template>
                    </v-select>
                    <!--select class="form-control" id="segment" v-model="form.segment" required>
                        <option value="" disabled selected hidden>Выберите сегмент аудитории</option>
                        <option>B2B</option>
                        <option>B2C</option>
                    </select-->
                </div>
                <div class="form-group">
                    <label for="person">Лицо принимающее решение</label>
                    <v-select :id="person" v-model="form.person" :options="['Владелец','Директор по маркетингу', 'Руководитель отдела продаж', 'Другое']" :searchable="true" :no-drop="false" :clearable="false" :placeholder="'Выберите лицо принимающее решение'">
                        <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!form.person"
                              v-bind="attributes"
                              v-on="events"
                            />
                         </template>
                    </v-select>
                    <!--select class="form-control" id="person" v-model="form.person" required>
                        <option value="" disabled selected hidden>Выберите лицо принимающее решение</option>
                        <option>Владелец</option>
                        <option>Директор по маркетингу</option>
                        <option>Директор по маркетингу</option>
                        <option>Руководитель отдела продаж</option>
                        <option>Другое:</option>
                    </select-->
                </div>
                <div class="form-group">
                    <label for="company_old">Сколько лет на рынке</label>
                    <v-select :id="company_old" v-model="form.company_old" :options="['0-2','3-5', 'более 6']" :searchable="true" :no-drop="false" :clearable="false" :placeholder="'Выберите сколько лет ваша компания на рынке'">
                        <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!form.company_old"
                              v-bind="attributes"
                              v-on="events"
                            />
                         </template>
                    </v-select>
                    <!--select class="form-control" id="company_old" v-model="form.company_old" required>
                        <option value="" disabled selected hidden>Выберите сколько лет ваша компания на рынке</option>
                        <option>0-2</option>
                        <option>3-5</option>
                        <option>более 6</option>
                    </select-->
                </div>
                <div class="form-group">
                    <label for="budget">Бюджет на маркетинг/рекламу в месяц</label>
                    <v-select :id="budget" v-model="form.budget" :options="['До 100 000 рублей/месяц','От 100 000 до 300 000 рублей/месяц', 'От 500 000 до 900 000 рублей/месяц', 'От 1 000 000 рублей/месяц']" :searchable="true" :no-drop="false" :clearable="false" :placeholder="'Ваш бюджет на маркетинг/рекламу в месяц'">
                        <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :required="!form.budget"
                              v-bind="attributes"
                              v-on="events"
                            />
                         </template>
                    </v-select>
                    <!--select class="form-control" id="budget" v-model="form.budget" required>
                        <option value="" disabled selected hidden>Ваш бюджет на маркетинг/рекламу в месяц</option>
                        <option>До 100 000 рублей/месяц</option>
                        <option>От 100 000 до 300 000 рублей/месяц</option>
                        <option>От 500 000 до 900 000 рублей/месяц</option>
                        <option>От 1 000 000 рублей/месяц</option>
                    </select-->
                </div>
                <label class="app__form-desc font-bold" for="analytics">Какие инструменты Вы использовали уже для осуществления маркетинговых задач</label>
                <div class="check-group" id="analytics">
                    <div class="form-check">
                        <input id="analytics1" class="form-check-input" value="Google Analytics" v-model="form.analytics" type="checkbox">
                        <label for="analytics1" class="form-check-label">
                            Google Analytics
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="analytics2" class="form-check-input" value="Яндекс.Метрика" v-model="form.analytics" type="checkbox">
                        <label for="analytics2" class="form-check-label">
                            Яндекс.Метрика
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="analytics3" class="form-check-input" value="Roistat или другие системы сквозной аналитики" v-model="form.analytics" type="checkbox">
                        <label for="analytics3" class="form-check-label">
                            Roistat или другие системы сквозной аналитики
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="analytics4" class="form-check-input" value="Azure или другие системы больших данных" v-model="form.analytics" type="checkbox">
                        <label for="analytics4" class="form-check-label">
                            Azure или другие системы больших данных
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="analytics5" class="form-check-input" value="Другое:" v-model="form.analytics" type="checkbox">
                        <label for="analytics5" class="form-check-label">
                            Другое:
                        </label>
                    </div>
                </div>
                <label class="app__form-desc font-bold" for="instruments">Какие инструменты Вы использовали уже для осуществления маркетинговых задач</label>
                <div class="check-group" id="instruments">
                    <div class="form-check">
                        <input id="instruments1" class="form-check-input" value="Контекстная реклама (Яндекс.Директ/Гугл.Эдс)" v-model="form.instruments" type="checkbox">
                        <label for="instruments1" class="form-check-label">
                            Контекстная реклама (Яндекс.Директ/Гугл.Эдс)
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="instruments2" class="form-check-input" value="Таргетированная реклама (ФБ, ВК, Майтаргет)" v-model="form.instruments" type="checkbox">
                        <label for="instruments2" class="form-check-label">
                            Таргетированная реклама (ФБ, ВК, Майтаргет)
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="instruments3" class="form-check-input" value="SEO – продвижение" v-model="form.instruments" type="checkbox">
                        <label for="instruments3" class="form-check-label">
                            SEO – продвижение
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="instruments4" class="form-check-input" value="SMM" v-model="form.instruments" type="checkbox">
                        <label for="instruments4" class="form-check-label">
                            SMM
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="instruments5" class="form-check-input" value="E-mail – маркетинг" v-model="form.instruments" type="checkbox">
                        <label for="instruments5" class="form-check-label">
                            E-mail – маркетинг
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="instruments6" class="form-check-input" value="Другое:" v-model="form.instruments" type="checkbox">
                        <label for="instruments6" class="form-check-label">
                            Другое:
                        </label>
                    </div>
                </div>
                <label class="app__form-desc font-bold" for="expectations">Ваши ожидания от маркетинга</label>
                <div class="check-group" id="expectations">
                    <div class="form-check">
                        <input id="expectations1" class="form-check-input" value="Увеличение количества входящего трафика" v-model="form.expectations" type="checkbox">
                        <label for="expectations1" class="form-check-label">
                            Увеличение количества входящего трафика
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="expectations2" class="form-check-input" value="Узнаваемость бренда" v-model="form.expectations" type="checkbox">
                        <label for="expectations2" class="form-check-label">
                            Узнаваемость бренда
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="expectations3" class="form-check-input" value="Увеличение количества входящих лидов/лидогенерация" v-model="form.expectations" type="checkbox">
                        <label for="expectations3" class="form-check-label">
                            Увеличение количества входящих лидов/лидогенерация
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="expectations4" class="form-check-input" value="Увеличение количества продаж/роста прибыли" v-model="form.expectations" type="checkbox">
                        <label for="expectations4" class="form-check-label">
                            Увеличение количества продаж/роста прибыли
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="expectations5" class="form-check-input" value="Анонсирование и продвижение нового продукта на рынке" v-model="form.expectations" type="checkbox">
                        <label for="expectations5" class="form-check-label">
                            E-mail – маркетинг
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="expectations6" class="form-check-input" value="Сокращение издержек CPA (уменьшение стоимости целевого действия)" v-model="form.expectations" type="checkbox">
                        <label for="expectations6" class="form-check-label">
                            Сокращение издержек CPA (уменьшение стоимости целевого действия)
                        </label>
                    </div>
                </div>
                <label class="app__form-desc font-bold" for="problems">Какие проблемы вы видите сейчас в маркетинге</label>
                <div class="check-group" id="problems">
                    <div class="form-check">
                        <input id="problems1" class="form-check-input" value="Низкий охват/мало показов" v-model="form.problems" type="checkbox">
                        <label for="problems1" class="form-check-label">
                            Низкий охват/мало показов
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="problems2" class="form-check-input" value="Низкая конверсия в заявки/звонки" v-model="form.problems" type="checkbox">
                        <label for="problems2" class="form-check-label">
                            Низкая конверсия в заявки/звонки
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="problems3" class="form-check-input" value="Низкая конверсия в продажи" v-model="form.problems" type="checkbox">
                        <label for="problems3" class="form-check-label">
                            Низкая конверсия в продажи
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="problems4" class="form-check-input" value="Низка рентабельность бизнеса" v-model="form.problems" type="checkbox">
                        <label for="problems4" class="form-check-label">
                            Низка рентабельность бизнеса
                        </label>
                    </div>
                    <div class="form-check">
                        <input id="problems6" class="form-check-input" value="Другое:" v-model="form.problems" type="checkbox">
                        <label for="problems6" class="form-check-label">
                            Другое:
                        </label>
                    </div>
                </div>
                <input type="hidden" v-model="form.test_id">
                <div class="app__form-btn">
                    <button type="submit" class="btn-default">Перейти на следующий шаг</button>
                </div>
                <div class="app__form-desc text-center">
                    Вы проходите 1 из 2 шагов теста «Диагностика маркетинга» 
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    export default {
        components: { vSelect },
        mounted() {
            $('.preloader').remove();
        },
        data: function () {
            return {
                form: {
                    name: this.$root.$data.name,
                    email: this.$root.$data.email,
                    phone: this.$root.$data.phone,
                    company: '',
                    site: '',
                    product: '',
                    segment: '',
                    person: '',
                    company_old: '',
                    budget: '',
                    analytics: [],
                    instruments: [],
                    expectations: [],
                    problems: [],
                    test_id: '1',
                    staff: '',
                    foundation: '',
                },
                error: ''
            }
        },
        methods: {
            onSubmit: function (e) {
              // console.log(this.form);
                let parent = this;
                $('body').append('<div class="preloader"></div>');
                axios.post('/api/v1/customer/create', this.form)
                    .then(function (response) {
                      parent.$root.$data.customer_id = response.data.customer_id;
                      parent.$root.$data.test_id = parent.form.test_id;
                      parent.$root.$data.current = 'questions-form';
                    })
                    .catch(function (error) {
                        parent.error = error;
                    });
            }
        }
    }
</script>
