/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./jquery.fancybox');
require('./maskedinput');
window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('welcome-form', require('./components/WelcomeForm').default);
Vue.component('profile-form', require('./components/ProfileForm').default);
Vue.component('questions-form', require('./components/QuestionsForm').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    data: {
        current: 'welcome-form',
        'customer_id': null,
        'test_id': null,
        'name': null,
        'email': null,
        'phone': null,
        isloading: false
    },
    computed: {
        currentComponent: function () {
            return this.current;
        }
    }
});
$('.fancybox').fancybox({

});
$('.masked-phone').inputmask("+7(999)999-99-99",{

});
