<template>
    <div class="container">
        <div class="app__title app__title-min cap text-center">Шаг 2 - Опрос по областям маркетинга</div>
        <div class="app__subtitle app__subtitle-min text-center">Выберите ситуации из 6 областей маркетинга, которые наиболее соответствуют маркетингу в вашей компании (можно выбрать только один ответ).</div>
        <div class="app__form app__form-var ">
            <form method="post" action="/process">
                <div v-for="(block, idx) in questions" class="questions-item">
                    <div class="app__form-title">{{ idx+1 }} блок - <span>{{ block.name }}</span></div>
                    <ol>
                        <li class="form-group" v-for="(question) in block.questions">
                            <div v-html="question.text" class="questions-item__text"></div>
                            <div class="form-check" v-for="(score, answer, idx) in question.answers">
                                <input required="required" class="form-check-input" type="radio" :id="question.id+'-'+idx" :value="score" :name="'blocks['+block.name+']['+question.id+']'">
                                <label class="form-check-label form-check-label-big" :for="question.id+'-'+idx">{{ answer }}</label>
                            </div>
                        </li>
                    </ol>
                </div>
                <input type="hidden" name="test_id" :value="test_id">
                <input type="hidden" name="customer_id" :value="customer_id">
                <input type="hidden" name="_token" :value="token">
                <div class="app__form-btn">
                    <button type="submit" class="btn-default">Показать результаты</button>
                </div>
                <div class="app__form-desc text-center">Вы проходите 2 из 2 шагов теста «Диагностика маркетинга»</div>
            </form>
        </div>
    </div>

</template>

<script>
    export default {
        mounted() {
            this.getTest();
            setTimeout(function(){
                $('.preloader').remove();
            },500);
        },
        data: function () {
            return {
                'test_id': this.$root.$data.test_id,
                'customer_id': this.$root.$data.customer_id,
                'token': $('meta[name="csrf-token"]').attr('content'),
                'industry': '',
                'questions': [],
                'checkedProblems': []
            }
        },
        methods: {
            getTest: function () {
                let parent = this;
                axios.post('/api/v1/test', {
                    test_id: this.test_id,
                    customer_id: this.customer_id,
                })
                    .then(function (response) {
                        parent.industry = response.data.test.industry;
                        parent.questions = response.data.questions;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        }
    }
</script>
