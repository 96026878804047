<template>
    <div class="container container-main">
        <div class="app__images">
            <img src="images/main_graf.svg" alt=""/>
            <div class="app__form-desc text-center">
                Время на тест – <span>8-15</span> минут
            </div>
        </div>
        <div class="app__right">
            <div class="app__title">Диагностика маркетинга за <span>8 мин</span></div>
            <div class="app__subtitle">
                Пройдите тест и узнайте, насколько эффективна ваша система продаж
            </div>
            <div v-if="error" class="alert alert-danger">{{error}}</div>
            <div class="app__form">
                <form v-on:submit.prevent="onSubmit">
                    <div class="form-group">
                        <label for="name">Имя</label>
                        <input type="text" class="form-control" id="name" placeholder="Введите ваше имя" v-model="form.name"
                               required>
                    </div>
                    <div class="form-group">
                        <label for="email">Емейл</label>
                        <input type="email" class="form-control" id="email" placeholder="Введите ваш емейл" v-model="form.email"
                               required>
                    </div>
                    <div class="form-group">
                        <label for="phone">Телефон</label>
                        <input type="tel" class="form-control" id="phone" placeholder="+7(___)___-__-__" v-model="form.phone"
                               required>
                    </div>
                    <input type="hidden" v-model="form.test_id">
                    <div class="form-group form-check agree-wrap">
                        <input type="checkbox" class="form-check-input" id="accept" v-model="form.accept" required>
                        <label class="form-check-label agree" for="accept">Я согласен на обработку персональных данных и ознакомлен с <a href="#" target="_blank">Пользовательским соглашением.</a></label>
                    </div>
                    <div class="app__form-btn">
                        <button type="submit" class="btn-default">Начать тест сейчас</button>
                    </div>
                    
                </form>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        mounted() {
            $('.preloader').remove();
        },
        data: function () {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    test_id: '1',
                    accept: false,
                },
                error: '',
            }
        },
        methods: {
            onSubmit: function (e) {
              $('body').append('<div class="preloader"></div>');
              this.$root.$data.name = this.form.name;
              this.$root.$data.email = this.form.email;
              this.$root.$data.phone = this.form.phone;
              this.$root.$data.current = 'profile-form';
            }
        }
    }
</script>
